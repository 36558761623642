.container {
  border: none;
  height: 48px;
  width: 48px;
  background-color: white;
  border-radius: 8px;
}

.checkedContainer {
  border: 3px solid #12b712;
}
