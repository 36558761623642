@import "../../theme.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  border: 1px solid $color-header-border;
  margin-top: 24px;
}

.createElementContainer {
  padding: 8px 0px;
  width: 100%;
}

.emptyDataContainer {
  padding: 8px 0px;
  text-align: center;
  width: 100%;
}

@media (max-width: 720px) {
  .container {
    margin-top: 0px;
    border: none;
  }
}
