.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.input {
  flex: 4;
  margin: 0px 12px;
  padding: 8px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 8px;
}
