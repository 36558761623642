$color-background-dark: #cedfff;
$color-background-light: #eaf2ff;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
}

.labelText {
  font-family: Roboto;
  font-size: 14px;
  margin-right: 8px;
  flex: 4;
  text-align: justify;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.darkElement {
  background: $color-background-dark;
}
.lightElement {
  background: $color-background-light;
}
.topContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.bottomContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.leftContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.doneElement {
  opacity: 0.5;
}
