@import "../../theme.scss";

.container {
  height: 50px;
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $color-header;
  border-bottom: 1px solid $color-header-border;
}

.logoContainer {
  display: flex;
  align-items: center;
}
