$color-saveButtonBorder: #b6c2d7;
$color-saveButtonContent: #dbe6f9;
$color-editButtonBorder: #b7b6b8;
$color-editButtonContent: #f5f4f5;
$color-deleteButtonBorder: #c9a8a4;
$color-deleteButtonContent: #eed2cd;
$color-addButtonBorder: #b6c2d7;
$color-addButtonContent: #478cf5;
$color-cancelButtonBorder: #c9a8a4;
$color-cancelButtonContent: #eed2cd;
$color-darkText: #333333;
$color-lightText: #ffffff;

.button {
  padding: 4px 12px;
  margin: 4px;
  border-radius: 8px;
  font-family: Roboto;
  font-weight: 700;
}

.saveButton {
  background-color: $color-saveButtonContent;
  border: 2px solid $color-saveButtonBorder;
  color: $color-darkText;
  &:hover {
    border: 2px solid $color-saveButtonContent;
  }
  &:active {
    background-color: $color-saveButtonBorder;
  }
}

.editButton {
  background-color: $color-editButtonContent;
  border: 2px solid $color-editButtonBorder;
  color: $color-darkText;
  &:hover {
    border: 2px solid $color-editButtonContent;
  }
  &:active {
    background-color: $color-editButtonBorder;
  }
}

.deleteButton {
  background-color: $color-deleteButtonContent;
  border: 2px solid $color-deleteButtonBorder;
  color: $color-darkText;
  &:hover {
    border: 2px solid $color-deleteButtonContent;
  }
  &:active {
    background-color: $color-deleteButtonBorder;
  }
}

.addButton {
  background-color: $color-addButtonContent;
  border: 2px solid $color-addButtonBorder;
  color: $color-lightText;
  &:hover {
    border: 2px solid $color-addButtonContent;
  }
  &:active {
    background-color: $color-addButtonBorder;
  }
}

.cancelButton {
  background-color: $color-cancelButtonContent;
  border: 2px solid $color-cancelButtonBorder;
  color: $color-darkText;
  &:hover {
    border: 2px solid $color-cancelButtonContent;
  }
  &:active {
    background-color: $color-cancelButtonBorder;
  }
}
