@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
body{margin:0;padding:0;font-family:Roboto}

.App_container__N2NZX{width:100vw;height:100vh}

.TAHeader_container__20e4q{height:50px;padding:0 12px;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;background-color:#dce7fa;border-bottom:1px solid #b6c2d7}.TAHeader_logoContainer__2XMRk{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}

.TAButton_button__3jgtv{padding:4px 12px;margin:4px;border-radius:8px;font-family:Roboto;font-weight:700}.TAButton_saveButton__1koHp{background-color:#dbe6f9;border:2px solid #b6c2d7;color:#333}.TAButton_saveButton__1koHp:hover{border:2px solid #dbe6f9}.TAButton_saveButton__1koHp:active{background-color:#b6c2d7}.TAButton_editButton__3oRoq{background-color:#f5f4f5;border:2px solid #b7b6b8;color:#333}.TAButton_editButton__3oRoq:hover{border:2px solid #f5f4f5}.TAButton_editButton__3oRoq:active{background-color:#b7b6b8}.TAButton_deleteButton__KLuRB{background-color:#eed2cd;border:2px solid #c9a8a4;color:#333}.TAButton_deleteButton__KLuRB:hover{border:2px solid #eed2cd}.TAButton_deleteButton__KLuRB:active{background-color:#c9a8a4}.TAButton_addButton__3a_58{background-color:#478cf5;border:2px solid #b6c2d7;color:#fff}.TAButton_addButton__3a_58:hover{border:2px solid #478cf5}.TAButton_addButton__3a_58:active{background-color:#b6c2d7}.TAButton_cancelButton__25Zy5{background-color:#eed2cd;border:2px solid #c9a8a4;color:#333}.TAButton_cancelButton__25Zy5:hover{border:2px solid #eed2cd}.TAButton_cancelButton__25Zy5:active{background-color:#c9a8a4}

.TAIconBar_icon__1Vejs{margin:4px 4px}

.TABody_container__2rUzi{max-width:720px;margin:auto;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}

.TAListView_container__TeLRf{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start;-webkit-flex:1 1;flex:1 1;border:1px solid #b6c2d7;margin-top:24px}.TAListView_createElementContainer__33n7f{padding:8px 0px;width:100%}.TAListView_emptyDataContainer__1tJuT{padding:8px 0px;text-align:center;width:100%}@media (max-width: 720px){.TAListView_container__TeLRf{margin-top:0px;border:none}}

.TAListElement_container__WfMc9{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-end;align-items:flex-end;-webkit-justify-content:space-between;justify-content:space-between;padding:8px 0px;width:100%}.TAListElement_labelText__3r1jM{font-family:Roboto;font-size:14px;margin-right:8px;-webkit-flex:4 1;flex:4 1;text-align:justify}.TAListElement_buttonContainer__1a1oG{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-flex:1 1;flex:1 1}.TAListElement_darkElement__2cn-o{background:#cedfff}.TAListElement_lightElement__1etZr{background:#eaf2ff}.TAListElement_topContainer__CAQ11{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between;width:100%}.TAListElement_bottomContainer__2U2z5{display:-webkit-flex;display:flex;-webkit-justify-content:flex-end;justify-content:flex-end;margin-right:16px}.TAListElement_innerContainer__1j5nP{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;justify-content:space-between;width:100%}.TAListElement_leftContainer__Z-DlL{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;padding:8px}.TAListElement_doneElement__37iO5{opacity:0.5}

.TAInputSave_container__7Ezi_{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between;width:100%}.TAInputSave_input__CX304{-webkit-flex:4 1;flex:4 1;margin:0px 12px;padding:8px}.TAInputSave_buttonContainer__2mExg{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-flex:1 1;flex:1 1;margin-right:8px}

.TACheckButton_container__1Dd88{border:none;height:48px;width:48px;background-color:white;border-radius:8px}.TACheckButton_checkedContainer__3LGgp{border:3px solid #12b712}

